import React, {useState} from 'react'
import { useQueryParams, useQueryParam, StringParam } from 'use-query-params'
import TechnologyList from './technologyList'
import Radar from './radar'
import Staff from './staff'
import TechnologyDetail from './technologyDetail'
import Tooltip from './tooltip'
import styles from "./technologyRadar.module.css"
import Logo from '../images/m-white.svg'
import RadarIcon from '../images/radar.svg'
import StaffIcon from '../images/staff.svg'

const TechnologyRadar = (props) => {
    const {technologies} = props
    const types = [...new Set(technologies.map(technology => technology.type).filter(type => type !== null))].sort()
    const stages = [...new Set(technologies.map(technology => technology.stage).filter(stage => stage !== null))].sort()
    const people = [...new Set(technologies.map(technology => technology.people).flat())].sort((a, b) => a.name.localeCompare(b.name))
    const [, setQuery] = useQueryParams({
        view: StringParam,
        technology: StringParam,
        type: StringParam,
        stage: StringParam,
        person: StringParam,
    })

    const [viewParam, setViewParam] = useQueryParam('view', StringParam)
    const [technologyHover, setTechnologyHover] = useState()
    const [technologyParam, setTechnologyParam] = useQueryParam('technology', StringParam)
    const [typeParam, setTypeParam] = useQueryParam('type', StringParam)
    const [stageParam, setStageParam] = useQueryParam('stage', StringParam)
    const [personParam, setPersonParam] = useQueryParam('person', StringParam)
    const technology = technologies.find(technology => technology.name === technologyParam)

    const view = ['radar', 'staff'].find(view => view === viewParam) || 'radar'
    if(!viewParam || view !== viewParam) {
        setViewParam(view, 'replaceIn')
    }
    
    if(technologyParam && technology === undefined) {
        setTechnologyParam(undefined, 'replaceIn')
    }

    const type = types.find(type => type === typeParam)
    if(typeParam && type === undefined) {
        setTypeParam(undefined, 'replaceIn')
    }

    const stage = stages.find(stage => stage === stageParam)
    if(stageParam && stage === undefined) {
        setStageParam(undefined, 'replaceIn')
    }

    const person = people.find(person => person?.name === personParam)
    if(personParam && person === undefined) {
        setPersonParam(undefined, 'replaceIn')
    }

    const handleView = view => {
        setQuery({
            view
        })
    }

    const handleClear = e => {
        setQuery({
            type: undefined,
            stage: undefined,
            person: undefined
        })
    }

    const handleType = e => {
        setQuery({
            type: e.value,
            stage: undefined,
            person: undefined
        })
    }

    const handleStage = e => {
        setQuery({
            type: undefined,
            stage: e.value,
            person: undefined
        })
    }

    const handlePerson = e => {
        setQuery({
            type: undefined,
            stage: undefined,
            person: e.value.name
        })
    }

    return (
        <div className={styles.techRadar}>
            <div className={styles.header}>
                <div className={styles.card}>
                    <Logo width="36px" height="36px"/>
                    <span>Technology radar</span>
                    <div className={styles.viewSelector} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleView(view === 'radar'? 'staff' : 'radar')}>
                        {
                            view === 'radar'? <RadarIcon/> : <StaffIcon/>
                        }
                    </div>
                </div>
            </div>
            <TechnologyList technologies={technologies} types={types} stages={stages} people={people} currentTechnology={technology} currentType={type} currentStage={stage} currentPerson={person} handleSelect={technology => setTechnologyParam(technology?.name)} handleHover={setTechnologyHover} handleClear={handleClear} handleType={handleType} handleStage={handleStage} handlePerson={handlePerson} className={styles.technologyList}/>
            {
                view === 'radar' && (
                    <Radar technologies={technologies} currentTechnology={technology} currentTechnologyHover={technologyHover} currentType={type} currentStage={stage} currentPerson={person} handleSelect={technology => setTechnologyParam(technology.name)} className={styles.radar}/>
                )
            }
            {
                view === 'staff' && (
                    <Staff people={people} currentTechnology={technology} handleSelect={technology => setTechnologyParam(technology?.name)} className={styles.staff}/>
                )
            }
            {
                view === 'radar' && technology && (
                    <TechnologyDetail currentTechnology={technology} currentType={type} currentStage={stage} currentPerson={person} handleDeselect={() => setTechnologyParam()} handleClear={handleClear} handleType={handleType} handleStage={handleStage} handlePerson={handlePerson} className={styles.technologyDetail}/>
                )
            }
            <Tooltip/>
        </div>
    )
}

export default TechnologyRadar
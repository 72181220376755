import React from 'react'
import ReactMarkdown from 'react-markdown'
import styles from './technologyDetail.module.css'
import CloseIcon from '../images/close.svg'


const TechnologyDetail = (props) => {
    const {currentTechnology, currentType, currentStage, currentPerson, handleDeselect, handleClear, handleType, handleStage, handlePerson, className} = props

    const CustomLink = (props) => {
        const {href, children} = props
        const target = href.match(/^https?:\/\//)? '_blank' : null
        return <a href={href} target={target}>{children}</a>
    }
    
    return (
        <div className={`${className} ${styles.technologyDetail}`}>
            <div className={styles.header}>
                <div className={styles.name}>{currentTechnology.name}</div>
                <div className={styles.close} onClick={handleDeselect} role='button' tabIndex={0} onKeyDown={() => {}}>
                    <CloseIcon/>
                </div>
            </div>
            {
                currentTechnology.description.length > 0? (
                    <div className={styles.section}>
                        <div className={styles.description}>
                            <ReactMarkdown source={currentTechnology.description} renderers={{link: CustomLink}}/>
                        </div>
                    </div>
                ) : null
            }
            {currentTechnology.type? (
                <div className={styles.section}>
                    <div className={styles.label}>Type</div>
                    <div className={`${styles.tag} ${currentTechnology.type === currentType? styles.active : ''}`} onClick={() => currentTechnology.type === currentType? handleClear() : handleType({value: currentTechnology.type})} role='button' tabIndex={0} onKeyDown={() => {}}>{currentTechnology.type}</div>
                </div>
            ) : null}
            {currentTechnology.stage? (
                <div className={styles.section}>
                    <div className={styles.label}>Stage</div>
                    <div className={`${styles.tag} ${currentTechnology.stage === currentStage? styles.active : ''}`} onClick={() => currentTechnology.stage === currentStage? handleClear() : handleStage({value: currentTechnology.stage})} role='button' tabIndex={0} onKeyDown={() => {}}>{currentTechnology.stage}</div>
                </div>
            ) : null}
            {currentTechnology.people.length? (
                <div className={styles.section}>
                    <div className={styles.label}>People</div>
                    <div className={styles.people}>
                        { 
                            currentTechnology.people.map((person, i) => <div key={i} className={`tooltip ${styles.person} ${person === currentPerson? styles.active : ''}`} aria-label={person?.name} data-tooltip={person?.name} style={person?.photo? {backgroundImage: `url(${person?.photo})`} : null} onClick={() => person === currentPerson? handleClear() : handlePerson({value: person})} role='button' tabIndex={0} onKeyDown={() => {}}/>)
                        }
                    </div>
                </div>
            ) : null }
        </div>
    )
}

export default TechnologyDetail
import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import TechnologyRadar from '../components/technologyRadar'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import './global.css'

const IndexPage = ({data}) => {

  const [hasMounted, setHasMounted] = useState(false)
  const people = (data?.people?.nodes ?? []).map(person => (
    {
      id: person.recordId,
      name: person.data.Display_name,
      photo: person.avatar?.publicURL ?? null
    }
  )).sort((a, b) => a?.name?.localeCompare?.(b?.name))
  const peopleById = people.reduce((dictionary, person) => {
    dictionary[person.id] = person
    return dictionary
  }, {})
  const technologies = data.technologies.nodes.map(technology => (
    {
      id: technology.recordId,
      name: technology.data.Name,
      description: technology.data.Description,
      type: technology.data.Type,
      stage: technology.data.Stage,
      people: (technology.data.People || []).map(id => peopleById[id]).filter(person => person)
    }
  ))
  technologies.sort((a, b) => a?.name?.localeCompare?.(b?.name))

  technologies.forEach(technology => {
    (technology?.people ?? []).forEach(person => {
      if(!person) return
      person.technologies = [...(person?.technologies ?? []), technology]
    })
  })

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <>
      <SEO title='Technology radar' description='See which tools and techs are on different stages of adoption in Manas, and what you can use for customer projects (Adopt) and internal ones (Assess, Trial).'/>
      <Router basepath='/'>
        {
            hasMounted? <TechnologyRadar path='/' people={people} technologies={technologies}></TechnologyRadar> : null
        }
      </Router>
    </>
  )
}

export const query = graphql`
query {
  technologies: allAirtableTechnologies {
    nodes {
      recordId
      data {
        Name
        Description
        Stage
        Type
        People
      }
    }
  },
  people: allAirtablePeople {
    nodes {
      recordId
      avatar {
        publicURL
      }
      data {
        Display_name
      }
    }
  }
}`

export default IndexPage
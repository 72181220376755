import React from 'react'
import styles from './staff.module.css'
import { tag, active } from './technologyDetail.module.css'
import BadMoodIcon from '../images/mood-bad.svg'


const Staff = ({ people, currentTechnology, handleSelect, className }) => {
    
    const filteredPeople = people.filter(person => currentTechnology === undefined || person.technologies.includes(currentTechnology))

    return (
        <div className={`${className} ${styles.staff}`}>
            {
                filteredPeople.map(person => (
                    <div key={person.id} className={styles.person}>
                        <div className={styles.avatar} style={{backgroundImage: `url(${person.photo})`}}/>
                        <h1>{person.name}</h1>
                        <div className={styles.techExperience}>
                            <h2>Tech experience</h2>
                            <div className={styles.technologies}>
                                {
                                    person.technologies.map(technology => (
                                        <div key={technology.id} className={`${tag} ${currentTechnology === technology? active : ''}`} onClick={() => currentTechnology === technology? handleSelect(undefined) : handleSelect(technology)} role='button' tabIndex={0} onKeyDown={() => {}}>{technology.name}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                filteredPeople.length === 0 && (
                    <div className={styles.nobody}>
                        <BadMoodIcon/>
                        <h1>{'Is there anybody out there?'}</h1>
                    </div>
                )
            }
        </div>
    )
}

export default Staff
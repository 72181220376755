import React, {useState, useEffect} from 'react'
import {Menu, MenuItem, MenuButton, SubMenu} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import styles from './technologyList.module.css'
import SearchIcon from '../images/search.svg'
import CloseIcon from '../images/close.svg'
import FilterIcon from '../images/filter.svg'

const TechnologyList = (props) => {
    const {technologies, types, stages, people, currentTechnology, currentType, currentStage, currentPerson, handleSelect, handleHover, handleClear, handleType, handleStage, handlePerson, className} = props
    const [search, setSearch] = useState('')
    let list

    const filteredTechnologies = technologies
        .filter(technology => technology.name.toLowerCase().includes(search.toLowerCase()))
        .filter(technology => currentType === undefined || technology.type === currentType)
        .filter(technology => currentStage === undefined || technology.stage === currentStage)
        .filter(technology => currentPerson === undefined || technology.people.includes(currentPerson))

        const changeHandler = (e) => {
        setSearch(e.target.value)
    }

    const searchHandler = (e) => {
        if(e.key === 'Escape') {
            setSearch('')
        }
    }

    const keyHandler = (e) => {

        let increment = 0
        switch(e.key) {
            case 'ArrowDown':
                increment = 1
                break
            case 'ArrowUp':
                increment = -1
                break
            case 'Escape':
                setSearch('')
                break
            default:
        }
        if(increment) {
            e.preventDefault()
            let index = filteredTechnologies.indexOf(currentTechnology) + increment
            if(index < 0) {
                index = filteredTechnologies.length - 1
            }
            if(index >= filteredTechnologies.length) {
                index = 0
            }
            handleSelect(filteredTechnologies[index])
        }
    }

    const clearHandler = () => {
        setSearch('')
    }

    useEffect(() => {
        if(currentTechnology && currentTechnology.ref) {
            if(currentTechnology.ref.offsetTop < list.scrollTop) {
                currentTechnology.ref.scrollIntoView({block: 'start'})
            } else if (currentTechnology.ref.offsetTop + currentTechnology.ref.clientHeight > list.clientHeight + list.scrollTop) {
                currentTechnology.ref.scrollIntoView({block: 'end'})
            }
        }
    }, [currentTechnology, list])

    useEffect(() => {
        document.addEventListener('keydown', keyHandler)
        return () => {
            document.removeEventListener('keydown', keyHandler)
        }
    })

    return (
        <div className={`${className} ${styles.technologyList}`}>
            <div className={styles.header}>
                <div className={styles.search}>
                    <SearchIcon className={styles.searchIcon}></SearchIcon>
                    <input type='text' placeholder='Search' onChange={changeHandler} onKeyDown={searchHandler} value={search}></input>
                    <CloseIcon className={styles.clearIcon} style={{visibility: search.length > 0? 'visible' : 'hidden'}} onClick={clearHandler}/>
                </div>
                <Menu menuButton={<MenuButton className={`${styles.filterButton} ${currentType || currentStage || currentPerson? styles.active : ''}`}><FilterIcon/></MenuButton>} align='start' offsetX={12} offsetY={0} arrow={false} position='anchor' viewScroll='close' key='right' direction='right'>
                    <MenuItem onClick={handleClear}>Clear filter</MenuItem>
                    <SubMenu label='Types'>
                        {
                            types.map((type, i) => <MenuItem key={type} value={type} onClick={handleType}>{type}</MenuItem>)
                        }
                    </SubMenu>
                    <SubMenu label='Stages'>
                        {
                            stages.map((stage, i) => <MenuItem key={stage} value={stage} onClick={handleStage}>{stage}</MenuItem>)
                        }
                    </SubMenu>
                    <SubMenu label='People'>
                        {
                            people.map((person, i) => <MenuItem key={i} value={person} onClick={handlePerson}>{person?.name ?? ""}</MenuItem>)
                        }
                    </SubMenu>
                </Menu>
                
            </div>
            <div ref={ref => list = ref} className={styles.list}>
                {
                    filteredTechnologies.length? filteredTechnologies.map(technology => (
                        <div key={technology.id} ref={ref => technology.ref = ref } className={`${styles.technology} ${technology === currentTechnology? styles.currentTechnology : ''}`} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => technology === currentTechnology? handleSelect(undefined) : handleSelect(technology)} onMouseOver={e => handleHover(technology)} onMouseOut={e => handleHover()} onFocus={() => {}} onBlur={() => {}}>
                            <span className={styles.name}>{technology.name}</span>
                        </div>
                    )) : <div className={styles.empty}>No matching technologies for '{search}'</div>
                }
            </div>
        </div>
    )
}

export default TechnologyList